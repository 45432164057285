import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference,
  DocumentChangeAction
} from '@angular/fire/firestore';
import { clone } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseItems } from './firebase-items';

@Injectable()
export abstract class FirebaseItemsAbstractService<T> implements FirebaseItems<T> {
  itemsCollection: AngularFirestoreCollection<T>;
  items: Observable<T[]>;
  filteredCollection: AngularFirestoreCollection<T>;

  // defaultItem?: any;

  constructor(public firebaseRoute: string, public afs: AngularFirestore) {
    this.itemsCollection = afs.collection<T>(firebaseRoute);
    this.items = this.getItems();
  }

  mapElements(action: DocumentChangeAction<T>) {
    const itemEl = action.payload.doc.data();

    itemEl['_id'] = action.payload.doc.id;

    return itemEl;
  }

  query(params?: any): Observable<any> {
    if (!params) {
      return this.getItems();
    }

    this.filteredCollection = this.afs.collection<T>(this.firebaseRoute, params);

    return this.filteredCollection.snapshotChanges().pipe(
      map(list => {
        return list.map<T>(this.mapElements.bind(this));
      })
    );
  }

  async add(item: T): Promise<DocumentReference> {
    // Persist a document id
    // let id = this.afs.createId();
    return await this.itemsCollection.add(item);
  }

  async set(item: any, data: any) {
    return await this.itemsCollection.doc(item._id).set(data);
  }

  getDocByKey(key: string): AngularFirestoreDocument<T> {
    return this.itemsCollection.doc(key);
  }

  findBy(field: string, value: string) {
    const findByFilterFunction = ref => ref.where(field, '==', value);

    return this.query(findByFilterFunction);
  }

  findOneBy(field: string, value: string): Observable<T> {
    return this.findBy(field, value).pipe(
      map((elements: T[]) => {
        if (elements) {
          return elements[0];
        } else {
          return null;
        }
      })
    );
  }

  getItems(): Observable<T[]> {
    return this.itemsCollection.snapshotChanges().pipe(
      map(list => {
        return list.map(this.mapElements.bind(this));
      })
    );
  }

  update(item: any, data: any) {
    return this.itemsCollection.doc(item._id).update(data);
  }

  updateByKey(key: string, data: any) {
    return this.itemsCollection.doc(key).update(data);
  }

  delete(item: T) {
    return this.itemsCollection.doc(item['_id']).delete();
  }

  toSimpleObject(item: any) {
    return JSON.parse(JSON.stringify(item));
  }

  toJSON(obj) {
    const json = {};

    Object.keys(obj).forEach(e => {
      json[e] = clone(obj[e]);
    });

    return json;
  }
}
