import { FirebaseItemsAbstractService } from '../firebase/firebase-items-abstract.service';
import { AdInterface } from 'src/model/ad.interface';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { UserService } from '../user/user.service';
export class AdsService extends FirebaseItemsAbstractService<AdInterface> {
  constructor(public afs: AngularFirestore, private user: UserService) {
    super('ads', afs);
  }

  getAll() {
    const query: QueryFn = (ref) => ref.orderBy('createdAt', 'desc');
    return this.query(query).pipe(
      map((adsList: AdInterface[]) => {
        adsList.forEach((element) => {
          element.gmapLocation = new google.maps.LatLng(element.geolocation.latitude, element.geolocation.longitude);
        });
        return adsList;
      })
    );
  }

  getActiveAds() {
    const limitDate = moment().add(-30, 'days');

    const query: QueryFn = (ref) => ref.where('active', '==', true).where('createdAt', '>', limitDate.toDate());
    return this.query(query).pipe(
      map((adsList: AdInterface[]) => {
        adsList.forEach((element) => {
          element.gmapLocation = new google.maps.LatLng(element.geolocation.latitude, element.geolocation.longitude);
        });
        return adsList;
      })
    );
  }

  getMyAds() {
    const query: QueryFn = (ref) => ref.where('owner', '==', this.user.currentUserId);
    return this.query(query).pipe(
      map((adsList: AdInterface[]) => {
        adsList.forEach((element) => {
          element.gmapLocation = new google.maps.LatLng(element.geolocation.latitude, element.geolocation.longitude);
        });
        return adsList;
      })
    );
  }
}
