import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { UserService } from 'src/providers/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private user: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('entra a resolver guardian');

    return this.user.authState.pipe(
      switchMap(() => {
        return this.user.$user.pipe(user => {
          return user;
        }, take(1));
      }),
      map(() => {
        return this.isAuthenticated();
      }),
      take(1)
    );
  }

  isAuthenticated(): boolean {
    console.log('est autenticado??', this.user.authenticated);
    if (this.user.authenticated) {
      if (!this.user.hasProvider(this.user.currentUser, 'password')) {
        this.router.navigate(['login']);

        return false;
      }
      // if (!this.user.primaryBusiness) {
      //   console.log('negocio no completado');
      //   this.router.navigate(['edit-business']);
      //   return false;
      // }
      // if (this.user.primaryBusiness && !this.user.primaryBusiness.address) {
      //   console.log('direccion de negocio no registrada');
      //   this.router.navigate(['edit-address']);
      //   return false;
      // }

      return true;
    } else {
      this.router.navigate(['/login']);

      return false;
    }
  }
}
