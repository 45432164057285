import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from 'src/guards';
const routes: Routes = [
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  {
    path: 'home',
    // canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then(m => m.RegistroPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioPageModule)
  },
  {
    path: 'anuncio',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./anuncio/anuncio.module').then(m => m.AnuncioPageModule)
  },
  {
    path: 'texto-legal',
    loadChildren: () => import('./texto-legal/texto-legal.module').then(m => m.TextoLegalPageModule)
  },
  {
    path: 'contactanos',
    loadChildren: () => import('./contactanos/contactanos.module').then(m => m.ContactanosPageModule)
  },
  {
    path: 'list-anuncios',
    loadChildren: () => import('./list-anuncios/list-anuncios.module').then(m => m.ListAnunciosPageModule)
  },
  {
    path: 'perfil-anuncio',
    loadChildren: () => import('./perfil-anuncio/perfil-anuncio.module').then(m => m.PerfilAnuncioPageModule)
  },
  {
    path: 'anuncio-creado',
    loadChildren: () => import('./anuncio-creado/anuncio-creado.module').then( m => m.AnuncioCreadoPageModule)
  },
  {
    path: 'pago-exitoso',
    loadChildren: () => import('./pago-exitoso/pago-exitoso.module').then( m => m.PagoExitosoPageModule)
  },
  {
    path: 'mis-anuncios',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./mis-anuncios/mis-anuncios.module').then( m => m.MisAnunciosPageModule)
  },
  {
    path: 'anuncios',
    loadChildren: () => import('./anuncios/anuncios.module').then( m => m.AnunciosPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
