import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private messages: string[];
  loading: HTMLIonLoadingElement;

  constructor(
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    private translateService: TranslateService
  ) {
    const loginErrors = [
      'auth/user-disabled',
      'auth/invalid-email',
      'auth/user-not-found',
      'auth/wrong-password',
      'auth/network-request-failed'
    ];
    const defaultMessages = ['WAIT_PLEASE'];
    this.translateService.get(defaultMessages.concat(loginErrors)).subscribe(values => {
      this.messages = values;
    });
  }

  async showLoading() {
    const options: LoadingOptions = {
      message: this.messages['WAIT_PLEASE']
    };

    this.loading = await this.loadingCtrl.create(options);

    await this.loading.present();
  }

  async dimissLoading() {
    if (this.loading) {
      return this.loading.dismiss();
    }
  }

  async showAlert(message, position?, duration?) {
    const toast = await this.toastCtrl.create({
      message,
      duration: duration || 3000,
      position: position || 'bottom'
    });

    toast.present();
  }

  async showError(errorCode, onErrorUnkwonMessage: string, position?, duration?): Promise<void> {
    const toast = await this.toastCtrl.create({
      color: 'danger',
      header: this.messages[errorCode] || undefined,
      message: onErrorUnkwonMessage || 'Ha ocurrido un error desconocido.',
      duration: duration || 3000,
      position: position || 'top'
    });

    toast.present();
  }
}
