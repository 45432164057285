import { Inject, Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import {
  ArxisIonicFireStoreAuthService as ArxisUser,
  ArxisSmsAuthService,
  ROUTE_FCM_DOC,
  ArxisFireStoreAuthService,
} from "@arxis/fireauth";
import { Firebase } from "@ionic-native/firebase/ngx";
import { Platform } from "@ionic/angular";
// import { Pro } from '@ionic/pro'
import * as firebase from "firebase/app";
import "firebase/auth";
import * as _ from "lodash";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ApiService } from "@arxis/api";
import { Roles } from "@arxis/fireauth/interfaces/roles";

export interface UserAccountInterface extends firebase.User {
  uid: string;
  name: string;
  nickname?: string;
  email: string;
  password: string;
  profilePic?: string;
  phone: string;
  type: string;
  acceptTerms?: boolean;
  hasLegalAge?: boolean;
  roles?: Roles;
}

@Injectable({
  providedIn: "root",
})
export class UserService extends ArxisFireStoreAuthService {
  recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  confirmationResult: any;
  preSavedAccountInfo: any;
  authState: Observable<any>;
  verificationId: any;
  readyState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get isAdmin() {
    if (!this.currentUser) {
      return false;
    }
    if (!this.currentUser.roles) {
      return false;
    }

    if (this.currentUser.roles.admin) {
      return true;
    }
    return false;
  }

  constructor(
    public api: ApiService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public platform: Platform
  ) {
    super(afAuth, afs);
  }

  authFillAction(user: firebase.User): Observable<any> {
    return super.authFillAction(user).pipe(
      switchMap((u: any) => {
        if (u) {
          return of(u).pipe(
            switchMap((us) => {
              return of(us);
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  mapElements(action) {
    const itemEl = action.payload.doc.data();
    itemEl.key = action.payload.doc.id;

    return itemEl;
  }

  async updateUserData(
    accountInfo,
    user?: UserAccountInterface,
    isNewUser?: boolean
  ): Promise<boolean> {
    // let path = `users/${this.currentUserId}`; // Endpoint on firebase
    user = user || this.currentUser;
    if (!user) {
      return Promise.reject({ code: "user-no-auth", message: "user-no-auth" });
    }
    const data = accountInfo;
    if (!isNewUser) {
      // await this.updateProfile(data).catch(error =>
      //   console.log('error al actualizar update profile', error)
      // );
    } else {
      // await this.updateDisplayName(data.name);
    }

    const userDoc = this.afs.collection("users").doc(this.currentUserId);
    return await userDoc.set(data).then(() => {
      return true;
    });
  }

  /**
   * Log the user out, which forgets the session
   */
  logout(): any {
    const logout = super
      .logout()
      .then(() => {
        this.emptyUserData();
        this.readyState.next(false);
      })
      .catch((error) => {
        // An error happened.
        console.log(error.message);
      });

    return logout;
  }

  emptyUserData() {
    // this.closeSubscriptions();
    this.userFireStoreDoc = null;
    // this.userFireStoreShards = null;
    this.readyState.next(false);
  }

  displayName(): any {
    if (this.authenticated) {
      if (this.currentUser.displayName) {
        return this.currentUser.displayName;
      } else if (this.currentUser) {
        return this.currentUser.name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  get photoURL(): any {
    if (this.authenticated) {
      if (this.currentUser.photoURL) {
        return this.currentUser.photoURL || null;
      } else if (this.currentUser) {
        return this.currentUser.photoURL || this.currentUser.profilePic || null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  // updateProfile(data: Account, user?: UserAccountInterface): Promise<any> {
  //   // this.updatePhoneNumber(data.phone);
  //   this.userFireStoreDoc.update(data).then(res => {
  //     console.log('updated on firebase');
  //   });
  //   if (data.email && data.email !== user.email) {
  //     this.updateEmail(data.email);
  //   }
  //   if (data.password) {
  //     return this.updatePassword(data.password).then(res => {
  //       this.login(data);

  //       return user.updateProfile({
  //         displayName: data.name,
  //         photoURL: data.profilePic
  //       });
  //     });
  //   }

  //   return user.updateProfile({
  //     displayName: data.name,
  //     photoURL: data.profilePic
  //   });
  // }

  updateDisplayName(name: string) {
    return this.currentUser.updateProfile({
      displayName: name,
      photoURL: this.currentUser.photoURL,
    });
  }

  updateEmail(email: string) {
    return this.currentUser.updateEmail(email);
  }

  updatePassword(password: string) {
    return this.currentUser.updatePassword(password);
  }

  updatePhoneNumber(phone) {
    // let credential = firebase.auth.PhoneAuthProvider.credential(this.confirmationResult.verificationId, code);
    // return this._user.updatePhoneNumber(credential);
  }

  get myPendingOrdersCount(): any {
    if (this.authenticated) {
      let total_count = 0;
      // this.userFireStoreShards.forEach(doc => {
      //   total_count += doc['pendingOrders'];
      // });

      return total_count;
    } else {
      return 0;
    }
  }

  get myActiveSalesCount(): any {
    // if (this.authenticated && this.primaryBusiness) {
    //   return this.primaryBusiness.pendingOrders || 0;
    // } else {
    //   return 0;
    // }
    return 0;
  }

  updateReadyState() {
    if (this.isReadyData()) {
      this.readyState.next(true);
    }

    return false;
  }

  isReadyData() {
    if (!this.authenticated) {
      return false;
    }

    return !!this.currentUser;
  }

  /**
   *
   * @param callBack
   * @deprecated
   */
  onReady(callBack: Function) {
    return this.readyState.subscribe((ready) => {
      if (ready) {
        callBack();
      }
    });
  }
}
