import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserService } from 'src/providers/user/user.service';

/**
 * Proteje páginas de invitado, redirigiendo a "Home" si el usuario ya ha iniciado sesión.
 */
@Injectable({
  providedIn: 'root'
})
class GuestGuard implements CanActivate {
  userSubscription: Subscription;

  constructor(protected userService: UserService, protected router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      this.userSubscription = this.userService.authState.subscribe(user => {
        if (this.userService.authenticated) {
          setTimeout(() => {
            // REVIEW: Investigar por qué o si es necesario
            this.router.navigate(['home']);
          });
          resolve(false);
        } else {
          resolve(true);
        }

        this.userSubscription.unsubscribe();
      });
    });
  }
}

export default GuestGuard;
