export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAYe0E35fHJPPx7CF04dcmh-sqntixnyb8',
    authDomain: 'placeme-dffec.firebaseapp.com',
    databaseURL: 'https://placeme-dffec.firebaseio.com',
    projectId: 'placeme-dffec',
    storageBucket: 'placeme-dffec.appspot.com',
    messagingSenderId: '737239586115',
    appId: '1:737239586115:web:cc845ec09289ba9d3c0bf6',
    measurementId: 'G-YHENR578T1'
  },
  version: '1.0.21'
};
