import { ModalController, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/providers/user/user.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { LoadingService } from 'src/providers/loading/loading.service';
@Component({
  selector: 'app-registro',
  templateUrl: './registro.page.html',
  styleUrls: ['./registro.page.scss']
})
export class RegistroPage implements OnInit {
  registerForm: FormGroup = new FormGroup({
    nickname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    hasLegalAge: new FormControl(false),
    acceptTerms: new FormControl(false, [Validators.requiredTrue])
  });

  constructor(
    private router: Router,
    private modalController: ModalController,
    private alertController: AlertController,
    private loadingService: LoadingService,
    private userService: UserService
  ) {}
  async dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    await this.loadingService.dimissLoading();
    await this.modalController.dismiss({
      dismissed: true
    });
  }
  async enviar() {
    await this.loadingService.showLoading();
    this.registerForm.get('hasLegalAge').setValue(true);
    const request = _.clone(this.registerForm.value);
    await this.userService
      .signup(request)
      .then(async user => {
        console.log('retonea ', user);
        const updateData = await this.userService.updateUserData(request, user, true).catch(e => {
          console.log('errr', e);
        });
        this.router.navigate(['inicio']);
        await this.dismiss();
      })
      .catch(async err => {
        await this.loadingService.dimissLoading();
      });
  }
  ngOnInit() {
   
  }
}
