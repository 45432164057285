import { MasonryGalleryModule } from 'ngx-masonry-gallery';
import { RegistroPageModule } from './registro/registro.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';
import { ArxisFireAuthModule } from '@arxis/fireauth';
import { ApiModule } from '@arxis/api';
import { AdsService } from 'src/providers/ads/ads';
import { Camera } from '@ionic-native/camera/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    MasonryGalleryModule,
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    RegistroPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ApiModule.forRoot({ url: 'http://examplex2.com' }),
    ArxisFireAuthModule.forRoot(environment.firebase)
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AdsService,
    Camera,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
