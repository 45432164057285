import { Component, OnInit } from "@angular/core";

import { Platform, NavController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { UserService } from "src/providers/user/user.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private user: UserService
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    this.initTranslate();
  }

  get isAuthenticated() {
    return this.user.authenticated;
  }

  get isAdmin() {
    return this.user.isAdmin;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  initTranslate() {
    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang("es");
    if (this.translate.getBrowserLang() !== undefined) {
      // this.translate.use(this.translate.getBrowserLang());
      this.translate.use("es"); // POR EL MOMENTO ESPAÑOL
    } else {
      this.translate.use("es"); // Set your language here
    }
  }

  logout() {
    this.user.logout();
    this.navCtrl.navigateRoot("inicio");
  }
}
